'use client'

import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'

const ErrorScreen = dynamic(() => import('@/ui/ErrorScreen/ErrorScreen').then((module) => ({
  default: module.ErrorScreen,
})))

const ErrorScreenMobile = dynamic(() => import('@/ui/ErrorScreenMobile/ErrorScreenMobile').then((module) => ({
  default: module.ErrorScreenMobile,
})))

export default function NotFoundPage() {
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0)
  const wrapper = typeof document !== 'undefined' && document.querySelector('.wrapper')

  const updateCurrentWidth = () => {
    const currentWidth =
      wrapper instanceof HTMLElement
        ? wrapper.offsetWidth
        : typeof window !== 'undefined' ? window.innerWidth : 0
    setWidth(currentWidth)
  }


  useEffect(() => {
    const currentWidth =
      wrapper instanceof HTMLElement
        ? wrapper.offsetWidth
        : typeof window !== 'undefined' ? window.innerWidth : 0
    setWidth(currentWidth)
  }, [wrapper])

  typeof window !== 'undefined' && window.addEventListener('resize', updateCurrentWidth)

  return (
    <div className={'wrapper'}>
      {width > 1440 ?
        <ErrorScreen
          type={'404'}
          title={'Мы не смогли ничего найти'}
          description={
            'Проверьте правильность введенного адреса или вернитесь на главную страницу'
          }
          buttonText={'Вернуться на главную'}
          handler={() => {
            window.location.replace(`/`)
          }}
        /> :
        <ErrorScreenMobile
          type={'404'}
          title={'Мы не смогли ничего найти'}
          description={
            'Проверьте правильность введенного адреса или вернитесь на главную страницу'
          }
          buttonText={'Вернуться на главную'}
          handler={() => {
            window.location.replace(`/`)
          }}
        />
      }
    </div>
  )
}

